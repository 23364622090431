import User from './User'

const UserAttributes = () => {
  const user = User()

  if (user != null) {
    const attributes = user.attributes
    console.log('PrintingUserAttribs: ', attributes)
    return attributes
  } else {
    console.log('PrintingUserAttribs: Null')
    return null
  }
}

export default UserAttributes
